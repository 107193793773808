<template>
  <router-view />
</template>

<script>
import { PushNotifications, } from '@capacitor/push-notifications'
import { FCM, } from '@capacitor-community/fcm'

export default {
  name: 'App',
  html() {
    return {
      title: 'App',
      meta: [
        { mid: 'theme-color', name: 'theme-color', content: '#3e4095', },
        { mid: 'viewport', name: 'viewport', content: 'initial-scale=1, viewport-fit=cover, user-scalable=no', },
      ],
    }
  },
  watch: {
    '$store.state.auth.token'(value) {
      if (value) {
        this.getDeviceToken()
      }
    },
  },
  beforeCreate() {
    $app.auth.init()
  },
  created() {
    this.saveDeviceToken()
  },
  methods: {
    getDeviceToken() {
      const isAvailable = Capacitor.isPluginAvailable('PushNotifications')
      if (isAvailable && $app.auth.loggedIn() && $app.auth.user().isPush) {
        PushNotifications.register()
        PushNotifications.addListener('registration', (token) => {
          if (Capacitor.getPlatform() === 'ios') {
            FCM.getToken().then((result) => this.saveDeviceToken(result.token))
          } else {
            this.saveDeviceToken(token.value)
          }
        })
      }
    },
    saveDeviceToken(token) {
      $api.profile.edit({ pushToken: token, }).then(() => {})
    },
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import '~nast-ui/styles/global';

@include initialize((
    default: (
        colors: (
            primary: #2A82D3,
            secondary: #4BB222,
        ),
        typography: (
            header-font: 'Mazzard, sans-serif',
            text-font: '400 1em Mazzard, sans-serif',
            h1-font: '400 2em var(--header-font)',
            h3-font: '500 1.2em var(--header-font)',
            text-color: #474747,
        ),
    ),
));

html {
  body {
    --n-modal-width: 100%;
    --line-height: 1.1;

    .n-input .n-wrapper, .n-input .n-content{
      border-radius: 10px;
    }
      .n-input {
      label {
        font-size: 14px;
        padding: 3px 0 5px;
        color: #BABABA;
      }
    //  .n-content {
    //    background: #FFF;
    //    border: 1px solid rgba(0, 0, 0, 0.2);
    //
    //  }
    //  input {
    //    font-size: 14px;
    //    line-height: 16px;
    //    color: inherit;
    //  }
    //  .n-wrapper, .n-content {
    //    border-radius: 10px;
    //    border-color: rgba(0, 0, 0, 0.2);
    //  }
    }
    .n-form-item {
      padding-top: 0 !important;

      label {
        position: static !important;
        opacity: 1;
      }
      &>label {
        font-size: 14px !important;
        line-height: 1.1 !important;
        color: #BABABA;
      }
    }

    .n-button {
      border: none;
      --n-button-border-radius: 50px;
      --n-button-padding: 13px 20px;
    }
  }
}

body {
  background-color: #fff;
  font-family: "Roboto", sans-serif;
  max-width: 700px;
  margin: 0 auto;
  --border-color: rgba(0, 0, 0, 0.2) !important;

  // ДЛЯ СТРАНИЦЫ ПОИСКА
  .search-page{
    .n-wrapper{
      .n-icon-left{
        margin-right: 0 !important;
      }
    }

    .n-input{
      .n-content{
        padding-left: 10px;
      }
    }

    .n-input-mini-input{
      input::placeholder{
        font-size: 12px;
        color: #ACACAC;
      }
    }
  }
  // КОНЕЦ ДЛЯ СТРАНИЦЫ ПОИСКА
}

h2 {
  font-weight: bold;
  font-size: 24px;
  color: #000;
  margin: 0;

}

h3 {
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  color: #BABABA;
  margin-bottom: 12px;
  margin-top: 0;
}

h5 {
  font-size: 12px;
  color: #868686;
  margin-bottom: 2px;
  font-weight: normal;
  margin-top: 0;
}

.container {
  padding: 0 15px;
}

.page-message {
  padding-top: 100px;
  opacity: .8;
  font-style: italic;
  text-align: center;
}

// ДЛЯ МОДАЛОК
.modal{
  .back {
    margin-bottom: 18px;
  }

  .n-card{
    border-radius: 17px;
  }

  .title{
    margin-bottom: 40px;

    h2{
      font-weight: bold;
      font-size: 24px;
      color: #676767;
      margin: 0 0 7px;
    }
  }

  .sub{
    font-size: 12px;
    color: #AD9A9A;

    a{
      text-decoration-line: underline;
      color: #2A82D3;
    }
  }
}


.n-tabs{
  margin-bottom: 10px;
  border: 1px solid #169401;
  border-radius: 100px;
  overflow: hidden;

  & > .n-tab{
    border-width: 0 !important;
    width: 50%;
    text-align: center;
    padding: 6px 20px;

    &.active{
      background-color: #169401;
      color: #fff !important;
    }
  }
}
</style>
